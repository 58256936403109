<template>
	<span class="mr-5 save-filters-btn-container">
		<w-btn
			@click.prevent="ask_for_new_filter_name"
			class="primary--text text--darken-1 primary lighten-5"
			data-test-id="save-filter-btn"
		>
			{{ l.t('filters.save-filter', 'Save filter') }}
		</w-btn>
		<FormModal v-model="new_filter_modal" @close="prompt_closed" width="500">
			<template v-slot:title>
				{{l.t('filters.new-filter-name', 'New filter name')}}
			</template>
			<div class="question-label">
				{{ l.t('call-logs.please-enter-name-of-your-filter', 'Please provide the name of your filter') }}
			</div>
			<div>
				<w-text-field
					v-model="new_filter_name"
					:rules="new_filter_modal ? [$validation.required()] : []"
					required
					class="w-100"
					@keyup="on_keyup"
					type="text"
					hide-details="auto"
					data-test-id="save-filter-new-filter-name"
				/>
			</div>
			<template v-slot:buttons>
				<w-btn color="secondary" @click="new_filter_modal = false">{{l.t('app.cancel', 'Cancel')}}</w-btn>
				<w-btn color="primary" @click="save_filter">{{l.t('app.save', 'Save')}}</w-btn>
			</template>
		</FormModal>
	</span>
</template>

<script>
import l from '../../../libs/lang';
import FormModal from './FormModal.vue';

export default {
	props: {
		generate_name_callback: {
			required: true,
			type: Function,
		}
	},
	components: { FormModal },
	data() {
		return {
			l,
			new_filter_name: null,
			new_filter_modal: false,
		};
	},
	methods: {
		ask_for_new_filter_name() {
			this.$data.new_filter_name = this.$props.generate_name_callback();
			this.$data.new_filter_modal = true;
		},
		prompt_closed() {
			this.$data.new_filter_name = null;
			this.$data.new_filter_modal = false;
		},
		save_filter() {
			if (this.$data.new_filter_name) {
				this.$emit('confirmed', this.$data.new_filter_name.trim());
				this.prompt_closed();
			} else {
				this.$data.new_filter_modal = true;
			}
		},
		on_keyup() {
			this.$data.new_filter_name = this.$data.new_filter_name.trimStart();
		}
	}
};
</script>
