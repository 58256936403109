<template>
    <div v-if="show_csr_warning" class="csr-header-warning text-center">
        {{l.t('app.you-are-in-csr-mode', 'You are in CSR mode')}}
    </div>
</template>
<script>
import {is_csr} from 'phoenix-session-helpers';
import l from '../../libs/lang';

export default {
    props: [],
    data() {
      return {
        l,
        show_csr_warning: false,
      };
    },
    async mounted() {
		if (this.$session && this.$session.user) {
			this.$data.show_csr_warning = await is_csr(this.$session);
		}
	}
};
</script>
