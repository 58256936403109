<template>
  <!-- :optional="true" removed because it would make problems on clicking on the already active tab when no routes invloved --> 
  <w-tabs
    v-model="current_route"
    grow
    centered
    center-active
    :ripple="false"
    slider-size="4"
    :show-arrows="true"
    class="submenu mx-auto"
    data-test-id="submenu-links"
    >
    <v-tab
      v-for="route in tabs"
      :tab-value="route.name"
      :to="prevent_routing ? undefined : { name: route.name, params: route_params }"
      :class="{'v-tab--active': [route.path, route.name].includes(current_route)}"
      :ripple="false"
      :style="{
        'max-width': `${100 / tabs.length}%`,
        'white-space': $vuetify.breakpoint.smAndDown ? 'nowrap' : ''
      }"
      class="nowrap"
      exact-path
      :data-test-id="`submenu-link-${route.name}`"
      :key="`submenu-${route.name}`"
      >
      {{ route.title }}
    </v-tab>
  </w-tabs>
</template>

<script>
import {consoleUserCanAccessPageMiddleware} from 'helpers';
import original_routes from '../../router'

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    routes: {
      type: Object,
      required: true,
    },
    route_params: {
      type: Object,
    },
    prevent_routing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      tabs: [],
      current_route: null,
    };
  },
  async created() {
    this.$data.tabs = await this.verify_tabs();

    this.$data.current_route = this.get_active_tab();
  },
  methods: {
    async verify_tabs() {
      const routes = this.$router ? this.$router.options.routes.filter((x) => Object.keys(this.$props.routes).includes(x.name)) : original_routes
      const allowed_routes = [];
      for (const routeName of Object.keys(this.$props.routes)) {
        const route = routes.find((x) => x.name === routeName);
        if (route && await consoleUserCanAccessPageMiddleware(route, this.$branding, this.$session)) {

          allowed_routes.push({
            ...this.routes[route.name],
            title: this.$props.routes[route.name].title,
            name: route.name,
          });
        }
      }

      return allowed_routes;
    },
    get_active_tab() {
      let current_route_name;
      if (this.$props.value) {
        current_route_name = this.$props.value
      } else {
        current_route_name = (this.$props.prevent_routing || !this.$route) ? this.$data.tabs[0].name : this.$route.name;
      }
      let route;
      for (const tab of this.$data.tabs) {
        if (tab.name === current_route_name) {
          route = tab;
        }
      }
      if (!route) {
        for (const tab of this.$data.tabs) {
          if (tab.subroutes && tab.subroutes.find((y) => y === current_route_name)) {
            route = tab;
            tab.path = this.take_route_path(route);
          }
        }
      }
      if (route) {
        return route.name;
      }
      
      return null;
    },
    take_route_path(route) {
      return this.$props.prevent_routing ? route.name : this.$router.options.routes.find((x) => x.name === route.name)['path'];
    },
  },
  watch: {
    current_route(v, prev_v) {
      if (!v && prev_v) this.$data.current_route = prev_v;
      this.$emit('input', v);
    }
  }
};
</script>
