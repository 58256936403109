<template>
    <div v-if="show && $session.user" id="account-box">
        <w-loader v-if="!$session.user.account" size="small" class="my-auto mx-auto"/>
        <v-menu
            v-else
            v-model="show_popup"
            :nudge-left="-226 - 21"
            :nudge-top="nudge_top || -5"
            :close-on-click="true"
            :close-on-content-click="false"
            content-class="rounded-borders"
            :ripple="false"
        >
           <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    class="my-auto d-flex"
                >
                    <LogoIcon
                        :size="['avo', 'avo_master'].includes($branding.data.code) ? 42 : 38"
                        :ripple="false"
                    />
                    <div id="account-info">
                        <w-loader v-if="loading" size="small" :message="false" :no_margins="true" />
                        <template v-else-if="$session.user.account">
                            <div class="ellipsis-text pr-4">{{ $session.user.account.contact.company }}</div>
                            <div class="ellipsis-text pr-4">{{ $session.user.account.contact.name}}</div>
                            <div class="ellipsis-text pr-4">{{ phone_number() | formatPhoneNumber }} </div>
                        </template>
                    </div>
                </div>
           </template>
           <v-card
            height="560"
            width="280"
            id="account-popup-content"
            class="text-center rounded-borders my-auto"
           >
                <w-btn id="account-popup-close" icon @click="show_popup = false">
                    <v-icon
                        color="text lighten-3"
                        size="22"
                        :ripple="false"
                    >
                      $vuetify.icons.close
                    </v-icon>
                </w-btn>
                <v-icon :size="60">
                    $vuetify.icons.avatar
                </v-icon>
                <div v-if="$session.user.account" id="account-popup-info" class="w-helper-text">
                    <div>{{ $session.user.account.contact.company }}</div>
                    <div>{{ $session.user.account.contact.name }} </div>
                    <div>{{ $session.user.account.contact.phone | formatPhoneNumber }} </div>
                    <div v-if="$session.user.account.default_voip_phone">
                        {{ l.t('app.ext', 'Ext') }} {{ $session.user.account.default_voip_phone.extension }}
                    </div>
                    <div class="account-information">
                        <div>{{ l.t('app.voip-id', 'VoIP ID') }}: {{ $session.user.account.id }}</div>
                        <div>
                            {{ l.t('app.account-id', 'Account ID') }}: {{ $session.user.account.billing_account_id }}
                        </div>
                        <div v-if="code && code.express_service_code" class="cursor-pointer">
                            {{ l.t('app.express-service-code', 'Express service code') }}:
                            <w-tooltip>
                                <template v-slot:activator>
                                    {{ code.express_service_code }}
                                </template>
                                {{ l.t('app.valid-until', 'Valid until') }}: {{ new Date(code.expire_date * 1000).toLocaleString() }}
                            </w-tooltip>
                        </div>
                        <w-loader v-if="code_loading" size="small" :message="false" :no_margins="true"></w-loader>
                    </div>
                </div>
                <v-divider class="light-divider"/>
                <v-menu
                    :rounded="true"
                    offset-y
                >
                    <template v-slot:activator="{ attrs, on }">
                        <div class="d-flex justify-center py-1 w-btn-medium language-switcher">
                            <span class="my-auto">{{ l.t('app.language', 'Language') }}:&nbsp;</span>
                            <div
                                v-bind="attrs"
                                v-on="on"
                                class="my-auto"
                            >
                                {{ language_list[language] }}
                            </div>
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                size="18"
                                color="text lighten-1"
                            >$vuetify.icons.dropdown_arrow</v-icon>
                        </div>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="item in Object.keys(language_list)"
                            :key="item.text"
                            link
                            @click="change_language(item)"
                        >
                            <v-list-item-title>{{ language_list[item] }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-divider class="light-divider"/>
                <div id="account-popup-links" class="w-btn-medium text--text cursor-pointer d-flex flex-column justify-center">
                    <div
                        v-if="($session.user && $session.user.is_subaccount) || ($session.user.account && $session.user.account.is_subaccount)"
                        @click="$global_emitter.$emit('use_main_account')"
                        data-test-id="use-main-account-switcher"
                    >
                           {{ l.t('app.switch-to-main-account', 'Switch to main account') }}
                       <v-icon color="primary" class="my-auto" size="18">$vuetify.icons.chevron_forward</v-icon>
                    </div>
                    <div
                        v-if="($session.user && $session.user.subaccounts_owner_is_subaccount)"
                        @click="$global_emitter.$emit('subaccounts_owner_user_main_account')"
                        data-test-id="subaccounts-owner-use-main-account-switcher"
                    >
                        {{ l.t('app.switch-to-main-account', 'Switch to main account') }}
                        <v-icon color="primary" class="my-auto" size="18">$vuetify.icons.chevron_forward</v-icon>
                    </div>
                    <a
                        v-if="help_link"
                        :href="help_link"
                        target="_blank"
                        :data-test-id="help_link"
                    >
                        {{ l.t('app.help', 'Help') }}
                        <v-icon color="primary" class="my-auto" size="18">$vuetify.icons.chevron_forward</v-icon>
                    </a>
                   <div
                        v-if="$branding.data.routes.includes('mobile-apps')"
                        @click="change_route('mobile-apps')"
                        data-test-id="mobile-apps-link"
                    >
                        {{ l.t('app.get-mobile-app', 'Get the mobile app') }}
                        <v-icon color="primary" class="my-auto" size="18">$vuetify.icons.chevron_forward</v-icon>
                   </div>
                </div>
                <w-btn
                    @click="sign_out"
                    color="text"
                    normal
                    class="mt-6 white--text w-btn-default"
                    data-test-id="account-box-sign-out"
                >
                    {{ l.t("menu.sign-out", "Sign out") }}
                </w-btn>
                <div v-if="version" id="version" class="nowrap">
                    {{ version }}
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
  import {getHelpLink} from 'helpers';
  import { readableAppVersion } from 'phonecom'
  import l from '../../libs/lang';
  import LogoIcon from '../elements/LogoIcon.vue';

  export default {
    props: ['nudge_top'],
    components: { LogoIcon, },
    data() {
      return {
        l,
        show_popup: false,
        language: l.detect(),
        loading: false,
        version: '',
        code: null,
        code_loading: false,
        show: true,
        help_link: null,
      };
    },
    async created() {
        const version = readableAppVersion();
        if (version) this.$data.version = `${l.t('app.version', 'Version')}: ${version}`;
        this.$forceUpdate();
        this.load_express_service_code(); // without async
        this.$global_emitter.$on('session-user-updated', () => this.$forceUpdate());
        this.$data.help_link = 'https://support.phone.com/';
    },
    methods: {
        change_language(lang) {
            this.$data.show_popup = false;
            this.$data.language = lang;
            this.$emit('language_changed', lang);
        },
        change_route(route) {
            this.$data.show_popup = false;
            this.$router.push({ name: route });
        },
        code_is_still_valid() {
            if (!this.$data.code) return false;
            if (!this.$data.code.expire_date) return false;
            return Date.now() < this.$data.code.expire_date * 1000;
        },
        async load_express_service_code() {
            if (this.code_is_still_valid()) return null;
            this.$data.code_loading = true;
            try {
                const codes = await this.$session.get_item('/express-service-codes');
                this.$data.code = codes.items[0];
            } catch (err) {
                this.$data.code = null;
            }
            this.code_loading = false;
            return true;
        },
        sign_out() {
            this.$data.loading = true;
            this.$emit('signOut');
            this.$data.show = false;
        },
        phone_number() {
            if (
                this.$session.user.account.default_voip_phone
                && this.$session.user.account.default_voip_phone.caller_id
                && this.$session.user.account.default_voip_phone.caller_id !== 'private'
            ) {
                return this.$session.user.account.default_voip_phone.caller_id;
            }

            return this.$session.user.account.contact.phone;
        },
    },
    computed: {
        language_list() {
          return this.$data.l.get_languages_list();
        },
        is_preprod() {
            return process.env.VUE_APP_STAGE === 'preprod';
        },
    },
    watch: {
        show_popup(val) {
            if (val) this.load_express_service_code();
        }
    },
  };
</script>

<style lang="scss">
	@import 'vue-apps-assets/styles/scss/vars.scss';
    #account-box {
        padding: 6px 10px;
        display: flex;
        height: $headerHeight;
        max-height: $headerHeight;
        width: 100%;
        max-width: $sidebar-width;
        text-align:left;
        letter-spacing: 0.2px;
        font-weight: 500 !important ;
        #account-info {
            margin-left: 8px;
            color: var(--v-text-lighten1) !important;
            font-size: 10px !important;
            line-height: 14px;
        }
    }
    #account-popup-content {
        padding: 44px 40px 30px 40px !important;
        #account-popup-info {
            margin-top: 6px;
            margin-bottom: 20px;
            color: var(--v-text-base) !important;
            .account-information {
                margin-top: 15px;
            }
        }
        #account-popup-links {
            margin-top: 20.5px;
            height: 118px;
            div {
                color: var(--v-primary-base) !important;
                margin-top: 9.5px;
                margin-bottom: 9.5px;
            }
        }
        #version {
            margin-top: 25px;
            font-size: 10px;
            line-height: 16px;
            color: var(--v-text-lighten1) !important;
        }
    }
    #lang-switch-btn {
        min-width: 0px !important;
    }
    .language-switcher {
        margin-top: 6px;
        margin-bottom: 6px;
        color: var(--v-text-base) !important;
    }
    .rounded-borders {
        border-radius: 30px !important;
        .v-menu__content {
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15), 0px 10px 30px rgba(0, 0, 0, 0.25) !important;
            border-radius: 30px !important;
            z-index: 10 !important;
        }
    }
    #account-popup-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }
</style>
