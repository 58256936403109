/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'
import { Contact } from './Contact'

const PageLimit = 80
const DefaultCursor = 'default'

/**
 *
 */
export class Contacts {
    items: Map<number, Contact> = new Map()
    hasMore = true
    total = 0
    cursor: string = Contacts.encodeCursor(DefaultCursor, '', false)

    /**
     * @param source
     */
    constructor (source: Contacts = null) {
        if (source) this.update(source)
    }

    /**
     * @param filters
     * @param limit
     * @param cursor
     * @param orderBy
     * @param inbox
     */
    static async load (cursor: string): Promise<Contacts> {
        const phoneCom = await getPhoneCom()

        const result = new Contacts()
        const contacts = new Contacts()
        let contact: Contact

        let extensionIds = []
        // inbox stuff WIP
        if (phoneCom) {
            const isNxt = phoneCom.user_tiered
            if (isNxt && phoneCom.extensions) {
                extensionIds = phoneCom.extensions.map((e): number => e.extension_id)
            }
        }

        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/load-contacts`
        const c: any = Contacts.decodeCursor(cursor)
        if (c.cursor === DefaultCursor) c.cursor = null
        const filter = c.filter
        // const inbox = c.inbox
        const body: any = { filters: { }, limit: PageLimit }
        // include company extensions
        // const extensions = window.V5PHONECOM.extensions
        // let extensionIds = []
        // if (inbox) extensionIds.push(inbox)
        // else extensionIds = extensions.map(e => e.extension_id)
        if (extensionIds.length > 0) body.extension_ids = extensionIds
        if (c.cursor) body.cursor = c.cursor
        if (filter) body.filters.keyword = filter
        // TODO: json validator lib
        const response = await ajax.postAsGet(requestUrl, body)
        // const response = await ajax.postAsGet(requestUrl, data)
        // if (response.message === 'Network Error') return 'network-error'
        // not real validation
        // const contacts = response?.data?.items?.map((i) => new Contact(...i))
        const data = response?.data || response
        if (!data || !data.items || data.items.length === 0) { return result }
        data.items.forEach(json => {
            contact = Contact.fromJson(json)
            contacts.items.set(contact.id, contact)
        })
        result.items = contacts.items
        result.hasMore = contacts.items.size === PageLimit
        result.total = data.total
        const remoteCursor = contacts.items.size > 0 ? data.items[data.items.length - 1].cursor : DefaultCursor
        result.cursor = Contacts.encodeCursor(remoteCursor, filter, result.hasMore)
        return result
    }

    /**
     * @param cursor
     * @param filter
     * @param hasMore
     * @param inbox
     */
    static encodeCursor = (cursor: string, filter: string, hasMore: boolean): string => {
        return cursor + '@' + filter + '@' + hasMore
    }

    /**
     * @param cursor
     */
    static decodeCursor = (cursor: string): any => {
        const items = cursor.split('@')

        if (items.length !== 3) return { cursor: DefaultCursor, filters: '', hasMore: false }
        return {
            cursor: items[0],
            filter: items[1],
            hasMore: items[2] === 'true'
        }
    }

    /**
     * @param updated
     */
    update (updated: Contacts) {
        if (updated === null) return this
        const combined = new Contacts()
        combined.items = this.items
        updated.items.forEach((v, k) => {
            combined.items.set(k, v)
        })
        combined.hasMore = updated.hasMore
        combined.cursor = updated.cursor
        combined.total = combined.items.size
        return combined
    }

    /**
     * @param c
     */
    add (c: Contact) {
        this.items.set(c.id, c)
    }

    /**
     * @param {number} contactId
     */
    remove (contactId: number) {
        if (this.items.has(contactId)) {
            this.items.delete(contactId)
        }
    }

    /**
     * @param {number} contactId
     */
    get (contactId: number) {
        if (this.items.has(contactId)) return this.items.get(contactId)
        return null
    }

    /**
     * @param {number} contactId
     */
    has (contactId: number) {
        return this.items.has(contactId)
    }

    /**
     * @param {number} contacts
     */
    sort (contacts: Contacts): Contacts {
        return null
    }

    /**
     * @param value
     * @param inbox
     */
    filter (value: string, inbox: number): Contacts {
        const filtered = new Contacts()
        this.items.forEach((c, contactId) => {
            if ((inbox === null || c.voipPhoneId === inbox) && (value === '' || c.includes(value))) filtered.add(c)
        })
        filtered.hasMore = this.hasMore
        filtered.cursor = Contacts.encodeCursor(DefaultCursor, value, this.hasMore)
        filtered.total = filtered.items.size
        return filtered
    }
}
