import html2pdf from 'html2pdf.js'
import { get_account_information } from 'phoenix-session-helpers'

const correctCss = (element) => { // pdf exporter has a bug in cloning node's css
    if (!element) return null
    const selectors = {
        '.d-flex': {
            prop: 'display',
            value: 'flex'
        },
        '.justify-space-between': {
            prop: 'justifyContent',
            value: 'space-between'
        }
    }

    for (const s of Object.keys(selectors)) {
        const els = element.querySelectorAll(s)
        if (els && els.length) {
            for (const el of els) {
                el.style[selectors[s].prop] = selectors[s].value
            }
        }
    }
}
/**
 *
 * @param {string} element_id
 * @param {string} fname
 * @param {object} session
 * @param {object} options
 * @param {Function} cb
 * @returns
 */
const exportPdf = async (element_id, fname, session, options = {}, cb) => {
    // https://github.com/eKoopmans/html2pdf.js?tab=readme-ov-file#advanced-usage
    // https://ekoopmans.github.io/html2pdf.js/
    const original_element = document.getElementById(element_id)
    if (!original_element) {
        throw new Error('Element not found')
    }
    const element = original_element.cloneNode(true)

    const account = await get_account_information(session)
    const account_info = `${account.name}/#${account.id}`
    const filename = fname || account_info

    const title = document.createElement('h6')
    title.textContent = filename
    title.style.marginBottom = '30px'
    element.prepend(title)

    const header = document.createElement('div')
    header.textContent = account_info
    header.style.fontWeight = 'bold'
    header.style.marginBottom = '10px'
    element.prepend(header)

    correctCss(element)

    if (typeof cb === 'function') {
        cb(element)
    }

    const setup = {
        margin: 0.5,
        filename: `${account_info} - ${filename}`,
        image: {
            type: 'jpeg',
            quality: 1
        },
        html2canvas: { scale: 1 },
        jsPDF: {
            unit: 'in',
            orientation: 'portrait',
            putOnlyUsedFonts: true
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        ...options
    }

    await html2pdf().from(element).set(setup).save()

    return true
}

export default exportPdf
