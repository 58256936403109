import 'core-js'
import * as React from 'react'
import VueWrapper from 'vue-wrapper'
import App from './App.vue'

/**
 * The main app for the console jsx to react from vuew
 *
 * @param {object | null} props  - react props
 */
export default function ConsoleApp (props) {
    return (
        <div>
            {VueWrapper({ ...props }, App)}
        </div>
    )
}
// const appAsReact = applyVueInReact(AA)
// export default appAsReact
